html,
body {
  height: 100%;
  width: 100vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E81C11;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #000000;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100vw;
  padding: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.74) 75.05%,
    rgba(255, 255, 255, 0) 100%
  );
}

@media (max-width: 767.98px) {
  form {
    padding: 24px;
  }
}

@media (min-width: 768px) {
  form {
    padding: 40px;
  }
}

.hallmark-logo {
  width: 80px;
  height: auto;
  margin-bottom: 32px;
}

@media (max-width: 767.98px) {
  form {
    width: 100%;
  }

  .input-group {
    flex-direction: column;
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    width: 100%;
  }

  .input-group > .input-group-append > .btn {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    width: 100%;
    margin-top: 8px;
  }
}

@media (min-width: 576px) {
  p {
    text-align: center;
  }
}

.content-wrapper {
  width: 600px;
  max-width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  text-align: center;
  padding: 30px;
}

.content-wrapper p {
  font-size: 20px;
  text-align: center;
}

.content-error {
  color: red;
  margin-top: 20px;
}

.content-form {
  padding: 20px 50px;
}

@media screen and (max-width: 450px) {
  .content-form {
    padding: 10px;
  }
}


.btn.btn-primary {
  background-color: rgb(228, 194, 0);
  background-image: url('https://1240369105.rsc.cdn77.org/moviegame2023/assets/button-golden-background-3x.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  color: #0E5432;
  font-weight: 500;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}